import React, { PropsWithChildren } from 'react';
import { FlexBox, responsive } from '@vp/swan';

const ResponsiveFlexBox = responsive(FlexBox);

export default function StepsContainer({
    children,
    isModal,
}: PropsWithChildren & { isModal: boolean }) {
    return (
        <ResponsiveFlexBox
            flexDirection="column"
            justifyContent="space-between"
            sm={{
                flexDirection: isModal ? 'column' : 'row',
            }}
            md={{
                flexDirection: 'column',
            }}
        >
            {children}
        </ResponsiveFlexBox>
    );
}
