import { Typography, tokens } from '@vp/swan';
import styled from 'styled-components';

const IconContainer = styled(Typography)`
    display: flex;
    align-items: flex-start;
    margin-top: ${tokens.SwanSemSpace2};
`;

export default IconContainer;
