import { Box } from '@vp/swan';
import styled from 'styled-components';

const StepIconWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 113px;
    height: 115px;
    margin-right: 20px;
`;

export default StepIconWrapper;
