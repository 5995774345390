/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type GetEventsQueryVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
}>;

export type GetEventsQuery = {
    __typename: 'Query';
    getCollaborationEventFeed: Types.Maybe<{
        __typename: 'GetEventFeedResponse';
        events: Array<{ __typename: 'Event' } & ActivityPanelEventFragment>;
    }>;
};

export type ActivityPanelEventFragment = {
    __typename: 'Event';
    id: string;
    type: Types.EventType;
    createdAt: any;
    content: Types.Maybe<
        | ({ __typename: 'CreateDeliveryContent' } & CreateDeliveryEventFragment)
        | ({ __typename: 'RequestRevisionContent' } & RequestRevisionEventFragment)
        | ({ __typename: 'AppointmentContent' } & ConfirmAppointmentEventFragment &
              RescheduleAppointmentEventFragment)
        | ({ __typename: 'StartCollaborationContent' } & StartCollaborationEventFragment)
    >;
};

export type CreateDeliveryEventFragment = {
    __typename: 'CreateDeliveryContent';
    versionId: number;
    message: string;
};

export type RequestRevisionEventFragment = {
    __typename: 'RequestRevisionContent';
    versionId: number;
    message: string;
};

export type StartCollaborationEventFragment = {
    __typename: 'StartCollaborationContent';
    slaHours: number;
};

export type ConfirmAppointmentEventFragment = {
    __typename: 'AppointmentContent';
    appointmentId: number;
    zoomMeetingUrl: string;
    startTime: Types.Maybe<any>;
    designerName: string;
    type: Types.AppointmentEventType;
    canReschedule: boolean;
    deliveryVersionId: number;
};

export type RescheduleAppointmentEventFragment = {
    __typename: 'AppointmentContent';
    appointmentId: number;
    zoomMeetingUrl: string;
    startTime: Types.Maybe<any>;
    designerName: string;
    type: Types.AppointmentEventType;
    canReschedule: boolean;
    deliveryVersionId: number;
};

export const CreateDeliveryEventFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CreateDeliveryEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CreateDeliveryContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                ],
            },
        },
    ],
};
export const RequestRevisionEventFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RequestRevisionEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'RequestRevisionContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                ],
            },
        },
    ],
};
export const ConfirmAppointmentEventFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ConfirmAppointmentEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AppointmentContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'appointmentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'designerName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canReschedule' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVersionId' } },
                ],
            },
        },
    ],
};
export const RescheduleAppointmentEventFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RescheduleAppointmentEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AppointmentContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'appointmentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'designerName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canReschedule' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVersionId' } },
                ],
            },
        },
    ],
};
export const StartCollaborationEventFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'StartCollaborationEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'StartCollaborationContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slaHours' } }],
            },
        },
    ],
};
export const ActivityPanelEventFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ActivityPanelEvent' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Event' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'CreateDeliveryContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CreateDeliveryEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'RequestRevisionContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RequestRevisionEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'AppointmentContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ConfirmAppointmentEvent',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RescheduleAppointmentEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'StartCollaborationContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'StartCollaborationEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CreateDeliveryEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CreateDeliveryContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RequestRevisionEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'RequestRevisionContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'StartCollaborationEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'StartCollaborationContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slaHours' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ConfirmAppointmentEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AppointmentContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'appointmentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'designerName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canReschedule' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVersionId' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RescheduleAppointmentEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AppointmentContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'appointmentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'designerName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canReschedule' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVersionId' } },
                ],
            },
        },
    ],
};
export const GetEventsDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getEvents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getCollaborationEventFeed' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'collaborationPublicId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'collaborationPublicId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'events' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'ActivityPanelEvent' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ActivityPanelEvent' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Event' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'CreateDeliveryContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'CreateDeliveryEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'RequestRevisionContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RequestRevisionEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'AppointmentContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ConfirmAppointmentEvent',
                                                },
                                            },
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'RescheduleAppointmentEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'StartCollaborationContent' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'StartCollaborationEvent',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'CreateDeliveryEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CreateDeliveryContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RequestRevisionEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'RequestRevisionContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'versionId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'StartCollaborationEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'StartCollaborationContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slaHours' } }],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ConfirmAppointmentEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AppointmentContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'appointmentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'designerName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canReschedule' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVersionId' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RescheduleAppointmentEvent' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AppointmentContent' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'appointmentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'zoomMeetingUrl' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'designerName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'canReschedule' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'deliveryVersionId' } },
                ],
            },
        },
    ],
};

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *   },
 * });
 */
export function useGetEventsQuery(
    baseOptions: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>
) {
    return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, baseOptions);
}
export function useGetEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>
) {
    return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(
        GetEventsDocument,
        baseOptions
    );
}
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
