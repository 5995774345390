import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import {
    COLLABORATE_BASE_PATH,
    DesignRevisionFeedbackPanel,
    ErrorLoadingState,
    RequestRevisionPanel,
    ResponsiveDrawerLayout,
    RevisionRequestPanelData,
    useCollaborationContext,
    useRevisionRequestPanelData,
} from '@99designs/collaborate-views';
import { __ } from '@99designs/i18n';
import { RevisionCanvas } from '../canvas/RevisionCanvas';

export type RevisionRouteParams = {
    deliveryVersionId: string;
};

export const Revision = () => {
    const { collaborationId } = useCollaborationContext();
    const { deliveryVersionId } = useParams<RevisionRouteParams>() as RevisionRouteParams;
    const {
        data: revisionRequest,
        loading,
        error,
        handleUpdate,
    } = useRevisionRequestPanelData({
        collaborationPublicId: collaborationId,
        deliveryVersionId: Number(deliveryVersionId),
    });

    return (
        <ErrorLoadingState data={revisionRequest} loading={loading} errors={error}>
            {(revisionRequest) => {
                const completedRevisionRequest = isRevisionRequestActive(revisionRequest);
                return (
                    <>
                        <Helmet>
                            {completedRevisionRequest ? (
                                <title>
                                    {__('Vistaprint | Design Services Revision Feedback')}
                                </title>
                            ) : (
                                <title>{__('Vistaprint | Design Services Request Revision')}</title>
                            )}
                        </Helmet>
                        <ResponsiveDrawerLayout
                            left={
                                <RevisionPanel
                                    revisionRequest={revisionRequest}
                                    handleUpdate={handleUpdate}
                                    deliveryVersionId={deliveryVersionId}
                                    collaborationId={collaborationId}
                                />
                            }
                            right={
                                <RevisionCanvas
                                    subRoute="revision"
                                    revisionRequestId={revisionRequest.id}
                                    deliveryId={deliveryVersionId}
                                    interactable={!isRevisionRequestActive(revisionRequest)}
                                    revisionRequest={revisionRequest}
                                    handleUpdate={handleUpdate}
                                />
                            }
                            hidePageOverlay={true}
                        />
                    </>
                );
            }}
        </ErrorLoadingState>
    );
};

// This Panel is currently only used on desktop.
export const RevisionPanel = ({
    revisionRequest,
    handleUpdate,
    deliveryVersionId,
    collaborationId,
}: {
    revisionRequest: RevisionRequestPanelData;
    handleUpdate: ({ comment }: { comment?: string | undefined }) => void;
    deliveryVersionId: string;
    collaborationId: string;
}) => {
    const navigate = useNavigate();
    const { revisionRequests, latestVersion } = useCollaborationContext();
    const { pinnedComments, generalComment } = revisionRequest;

    if (isRevisionRequestActive(revisionRequest)) {
        return (
            <DesignRevisionFeedbackPanel
                version={deliveryVersionId}
                remainingRevisions={revisionRequests.remaining}
                hasUnlimitedRevisions={revisionRequests.isUnlimited}
                collaborationId={collaborationId}
                pinnedComments={pinnedComments}
                generalComment={generalComment || ''}
                onClick={() =>
                    navigate(
                        `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${latestVersion}/activity`
                    )
                }
                files={revisionRequest.files}
            />
        );
    }

    return (
        <RequestRevisionPanel
            remainingRevisions={revisionRequests.remaining}
            hasUnlimitedRevisions={revisionRequests.isUnlimited}
            revisionRequest={revisionRequest}
            onChange={handleUpdate}
        />
    );
};

function isRevisionRequestActive(revisionRequest: RevisionRequestPanelData) {
    return revisionRequest.state !== 'REVISION_REQUEST_STATE_PENDING';
}
