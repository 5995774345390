import { useEffect, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import {
    Box,
    Column,
    FlexBox,
    FluidImage,
    GridContainer,
    Row,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    Typography,
} from '@vp/swan';
import { getCurrentCulture } from '@99designs/design-services-common';
import { useIdentityContext } from '@99designs/design-services-common';
import { Maybe } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { Format } from '@99designs/ninedown';
import { useCart } from '../../../../lib/hooks';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { FormInput } from '../../../BriefForm';
import { Field_GridChoiceField_Fragment } from '../../brief.generated';
import { useUpdateGridChoiceField } from './useUpdateGridChoiceField';

export type GridChoiceFieldProps = Field_GridChoiceField_Fragment & {
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
};

type LabelProps = {
    image: Maybe<string>;
    label: string;
    isViewOnly?: boolean;
};

function Label({ image, label, isViewOnly = false }: LabelProps) {
    return (
        <SelectionSetLabel
            style={{
                overflow: 'hidden',
                width: '100%',
                maxWidth: '154px',
                minWidth: '100px',
                alignItems: 'flex-start',
                minHeight: '96px',
                height: '100%',
                pointerEvents: isViewOnly ? 'none' : undefined,
            }}
        >
            {image && (
                <FluidImage
                    src={image}
                    alt={label}
                    paddingTop={4}
                    paddingBottom={3}
                    paddingX={4}
                    style={{
                        objectFit: 'cover',
                        width: '80%',
                        height: '80%',
                        minHeight: '64px',
                        objectPosition: 'left',
                    }}
                />
            )}
            <Box textAlign="left" paddingLeft={5} paddingRight={3} paddingBottom={5}>
                <Typography fontWeight="bold">{label}</Typography>
            </Box>
        </SelectionSetLabel>
    );
}

export function Input({
    id,
    required,
    options,
    register,
    setValue,
    choiceValue,
    immutable,
}: GridChoiceFieldProps) {
    const DEFAULT_VALUE = options[0].value;
    const locale = getCurrentCulture();
    const { briefId } = useBriefFormContext();
    const { accessToken, shopperId } = useIdentityContext();
    const { isInCart } = useCart(locale, { accessToken, shopperId });
    const [selectedValue, setSelectedValue] = useState<string | null>(choiceValue || DEFAULT_VALUE);
    const updateMutation = useUpdateGridChoiceField(id, briefId, choiceValue);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setValue(id, selectedValue);
        if (selectedValue) {
            updateMutation(selectedValue);
        }
    }, [id, setValue, selectedValue, updateMutation]);

    const hasImages = options.some((o) => o.image != null);

    useEffect(() => {
        async function checkCartStatus() {
            if (briefId) {
                const inCart = await isInCart(briefId);
                setDisabled(!!immutable && inCart);
            }
        }

        checkCartStatus();
    }, [briefId, immutable, choiceValue, isInCart]);

    return (
        <SelectionSet
            aria-label={id}
            variant="single-select"
            skin={hasImages ? 'tiles-vertical' : 'simple-column'}
            selectedValue={selectedValue}
            onSelectedValueChange={(newSelectedValue) => setSelectedValue(newSelectedValue)}
        >
            <GridContainer>
                <FlexBox>
                    {options?.map(({ value, label, image }) => (
                        <SelectionSetInput
                            disabled={disabled}
                            key={value}
                            value={value}
                            {...register(id, {
                                required:
                                    required && !disabled ? __('This field is required') : false,
                            })}
                        >
                            <Label image={image} label={label} />
                        </SelectionSetInput>
                    ))}
                </FlexBox>
                <Row>
                    <Column padding={3} span={12}>
                        {options.map(
                            ({ subtext, value }) =>
                                value === selectedValue && (
                                    <div
                                        key={value}
                                        className="swan-text-small swan-px-1"
                                        dangerouslySetInnerHTML={{
                                            __html: Format(subtext ?? ''),
                                        }}
                                    />
                                )
                        )}
                    </Column>
                </Row>
            </GridContainer>
        </SelectionSet>
    );
}

type GridChoiceFieldViewProps = Field_GridChoiceField_Fragment & {
    colWidthOverride?: NonNullable<1 | 2 | 4 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>;
};

export function View(props: GridChoiceFieldViewProps) {
    const valueToChoiceData = Object.fromEntries(
        props.options.map((option) => {
            return [option.value, option];
        })
    );

    if (!props.choiceValue) {
        return null;
    }

    const { value, image, label } = valueToChoiceData[props.choiceValue];

    const hasImages = props.options.some((o) => o.image != null);

    return (
        <SelectionSet variant="single-select" skin={hasImages ? 'tiles-vertical' : 'simple-column'}>
            <GridContainer>
                <Row>
                    <Column span={props.colWidthOverride ?? 4} key={value}>
                        <SelectionSetInput value={value} marginRight={'4'}>
                            <Label image={image} label={label} isViewOnly={true} />
                        </SelectionSetInput>
                    </Column>
                </Row>
            </GridContainer>
        </SelectionSet>
    );
}

export function Review(props: GridChoiceFieldViewProps) {
    const valueToChoiceData = Object.fromEntries(
        props.options.map((option) => {
            return [option.value, option];
        })
    );

    if (!props.choiceValue) {
        return null;
    }

    const { image, label } = valueToChoiceData[props.choiceValue];

    return (
        <FlexBox display="flex" alignItems="flex-start">
            {image && (
                <FluidImage
                    src={image}
                    alt={label}
                    style={{
                        objectFit: 'cover',
                        width: '32px',
                        height: '32px',
                        objectPosition: 'left',
                    }}
                />
            )}
            <Typography>{label}</Typography>
        </FlexBox>
    );
}
