import { ChangeEvent, useEffect, useState } from 'react';
import {
    Box,
    Dropdown,
    DropdownOption,
    FormError,
    FormLabel,
    Spinner,
    VisuallyHidden,
} from '@vp/swan';
import styled from 'styled-components';
import { bugtracker } from '@99designs/design-services-common';
import { ProductOption } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useBriefContext } from '../../../../BriefContext/BriefContext';
import { useBriefFormContext } from '../../../../BriefContext/BriefFormContext';
import { ClientConfiguredFieldProps } from '../types';
import {
    useCompatibleOptionsQuery,
    usePrintProductOptionsQuery,
} from './product-options.generated';
import useUpdateProductOptionsDropdown from './useUpdateProductOptionsDropdown';

const DropdownWrapper = styled.div`
    position: relative;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
`;

const StyledDropdown = styled(Dropdown)<{ loading?: boolean }>`
    pointer-events: ${({ loading }) => (loading ? 'none' : undefined)};
`;

const FieldWrapper = styled.div<{ opacity?: number }>`
    opacity: ${({ opacity }) => opacity};
`;

export function Input({
    id,
    clientConfiguredValue,
    setValue,
    register,
    error,
}: ClientConfiguredFieldProps) {
    const { product } = useBriefContext();
    const { briefId } = useBriefFormContext();

    const [options, setOptions] = useState<ProductOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});

    const updateMutation = useUpdateProductOptionsDropdown(id, briefId, clientConfiguredValue);

    useEffect(() => {
        if (clientConfiguredValue) {
            // The type here is not validated.

            setSelectedOptions(JSON.parse(clientConfiguredValue));
        }
    }, [clientConfiguredValue]);

    const toSelectedOptions = () => {
        return [...Object.entries(selectedOptions)].map(([key, value]) => ({ key, value }));
    };

    const {
        data: merchantProductOptions,
        loading: merchantProductOptionsLoading,
        error: merchantProductOptionsError,
    } = usePrintProductOptionsQuery({
        variables: {
            input: {
                mpvId: product?.fulfilmentMpvId || '',
                tenant: '',
            },
        },
        skip: !product || !product.key || !product.fulfilmentMpvId,
        fetchPolicy: 'cache-and-network',
        onError: (e) => {
            bugtracker.notify(`Failed to get product options: ${e.message}`);
        },
    });

    const { loading: optionsLoading, error: optionsError } = useCompatibleOptionsQuery({
        variables: {
            request: {
                productKey: product?.key || '',
                options: toSelectedOptions(),
                merchantProductOptions: merchantProductOptions?.printProductOptions,
            },
        },
        skip: !merchantProductOptions || !product || !product.key,
        fetchPolicy: 'cache-and-network',
        onError: (e) => {
            bugtracker.notify(`Failed to get compatible options: ${e.message}`);
        },
        onCompleted: (data) => {
            setOptions(data.compatibleOptions);
        },
    });

    const loading = optionsLoading || merchantProductOptionsLoading;
    const queryError = merchantProductOptionsError || optionsError;
    if (queryError || options.length === 0) {
        return null;
    }

    const handleChange = async (option: ProductOption, event: ChangeEvent<HTMLSelectElement>) => {
        const target = event.target.value;

        setValue(`${id}.${option}`, target);

        const newSelectedOptions = {
            ...selectedOptions,
            [option.name]: target,
        };

        setSelectedOptions(newSelectedOptions);

        await updateMutation(JSON.stringify(newSelectedOptions));
    };

    return (
        <>
            {options.map((option) => {
                const isOptionSelected = Object.keys(selectedOptions).includes(option.name);
                return (
                    <DropdownWrapper key={option.name}>
                        <FieldWrapper opacity={loading && !isOptionSelected ? 0.5 : 1}>
                            <FormLabel htmlFor={id + option.name} marginBottom={'3'}>
                                {option.translatedName}
                                <Box aria-hidden="true" as="span" ml={'2'}>
                                    *
                                </Box>
                                <VisuallyHidden>({__('Required')})</VisuallyHidden>
                            </FormLabel>
                            {error && Object.keys(error as object).includes(option.name) && (
                                <Box mb={'4'}>
                                    <FormError id={`${id}.${option.name}+error`} marginTop={'4'}>
                                        {__('This field is required')}
                                    </FormError>
                                </Box>
                            )}
                            <StyledDropdown
                                {...register(`${id}.${option.name}`, {
                                    required: true,
                                    onChange: (event) => handleChange(option, event),
                                })}
                                value={selectedOptions[option.name] || ''}
                                fullWidth
                                mb={5}
                                loading={loading}
                            >
                                <DropdownOption key={`${option.name}-empty`} value="" hidden>
                                    {__('Select {{optionTitle}}', {
                                        optionTitle: option.translatedName,
                                    })}
                                </DropdownOption>
                                {option.availableOptions.map((i) => (
                                    <DropdownOption
                                        key={`${option.name}-${i.key}`}
                                        value={i.key}
                                        disabled={i.disabled}
                                    >
                                        {i.value}
                                    </DropdownOption>
                                ))}
                            </StyledDropdown>
                        </FieldWrapper>
                        {loading && !isOptionSelected && (
                            <StyledSpinner accessibleText={__('Loading...')} layout="horizontal" />
                        )}
                    </DropdownWrapper>
                );
            })}
        </>
    );
}
