import React from 'react';
import { Box, H2 } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { RemainingRevisions } from './RemainingRevisions';

export interface RevisionRequestMetaProps {
    remainingRevisions: number;
    hasUnlimitedRevisions: boolean;
}

export function RevisionRequestMeta(props: RevisionRequestMetaProps) {
    return (
        <Box>
            <H2 fontSkin="title-section">{__('Request a design revision')}</H2>
            <RemainingRevisions
                remainingRevisions={props.remainingRevisions}
                hasUnlimitedRevisions={props.hasUnlimitedRevisions}
            />
        </Box>
    );
}
