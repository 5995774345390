import React, { useEffect } from 'react';
import {
    Button,
    FlexBox,
    Icon,
    TextArea,
    Typography,
    responsive,
    tokens,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { useActionContext } from '../../context';
import { RevisionRequestPanelData, useUploadFiles } from '../../hooks';
import { PinCommentsFormComponent, RevisionRequestMeta } from '../../ui';
import { DiscardButton } from './DiscardButton';
import { SubmitButton } from './SubmitButton';
import { UploadArea, UploadedFiles } from './components';

const Container = responsive(FlexBox);

const ActionButtonArea = styled.div`
    width: 100%;
    padding: ${tokens.SwanSemSpace6} ${tokens.SwanSemSpace7};
    border-top: 1px solid ${tokens.SwanSemColorBorderStandard};

    @media (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
        border-top: none;
    }
`;

export interface RevisionRequestFormProps {
    remainingRevisions: number;
    hasUnlimitedRevisions: boolean;
    revisionRequest: RevisionRequestPanelData;
    onChange: (data: { comment?: string }) => void;
}

export function RequestRevisionPanel({
    onChange,
    revisionRequest,
    ...props
}: RevisionRequestFormProps) {
    const [generalComment, setGeneralComment] = React.useState<string | undefined>(
        revisionRequest.generalComment
    );

    const {
        uploadedFiles,
        removeUploadedFile,
        addUploadedFile,
        uploadStarted,
        uploadCompleted,
        isSubmitEnabled,
    } = useUploadFiles({ files: revisionRequest.files });

    useEffect(() => {
        onChange({
            comment: generalComment,
        });
    }, [generalComment, onChange]);

    const { disableActions, enableActions } = useActionContext();

    useEffect(() => {
        // disable general CTAs when editing a revision request
        disableActions();

        return () => {
            enableActions();
        };
    }, [disableActions, enableActions]);

    return (
        <Container
            flexDirection="column"
            justifyContent="flex-start"
            style={{ height: '100%' }}
            sm={{ justifyContent: 'space-between' }}
        >
            <FlexBox
                flexDirection="column"
                gap="between-subsections"
                paddingX="7"
                paddingY="10"
                style={{ overflowY: 'auto' }}
            >
                <RevisionRequestMeta
                    remainingRevisions={props.remainingRevisions}
                    hasUnlimitedRevisions={props.hasUnlimitedRevisions}
                />
                <PinCommentsFormComponent comments={revisionRequest.pinnedComments} />
                <GeneraCommentFormComponent
                    setGeneralComment={setGeneralComment}
                    comment={generalComment}
                />
                <UploadArea
                    addUploadedFile={addUploadedFile}
                    uploadStarted={uploadStarted}
                    uploadCompleted={uploadCompleted}
                    revisionRequestId={revisionRequest.id}
                />
                <UploadedFiles files={uploadedFiles} removeUploadedFile={removeUploadedFile} />
            </FlexBox>
            <ActionButtonArea>
                <ActionButtons
                    revisionRequestId={revisionRequest.id}
                    isSubmitEnabled={isSubmitEnabled}
                />
            </ActionButtonArea>
        </Container>
    );
}

function GeneraCommentFormComponent({
    comment,
    setGeneralComment,
}: {
    comment?: string;
    setGeneralComment: (comment: string) => void;
}) {
    const [canShowComment, setCanShowComment] = React.useState<boolean>(comment !== '');

    return (
        <FlexBox flexDirection="column">
            {canShowComment ? (
                <>
                    <Typography mb={'3'} fontSize="small" fontWeight="bold">
                        {__('Leave a general comment')}
                    </Typography>
                    <TextArea
                        value={comment}
                        data-testid="general-comment"
                        onChange={(event) => setGeneralComment(event.target.value)}
                        placeholder={__('Write a note to the designer')}
                        autoFocus={true}
                        rows={4}
                        style={{ fontSize: tokensRaw.SwanBaseFontSize100 }}
                    />
                </>
            ) : (
                <Button
                    skin="tertiary"
                    onClick={() => setCanShowComment(true)}
                    data-testid="general-comment-button"
                    iconPosition="left"
                    width="full-width"
                >
                    <Icon iconType="plus" /> {__('Add a general comment')}
                </Button>
            )}
        </FlexBox>
    );
}

export const ActionButtons = ({
    isSubmitEnabled,
    revisionRequestId,
}: {
    isSubmitEnabled: boolean;
    revisionRequestId: number;
}) => {
    const screenClass = useScreenClass();
    const spinnerSize = screenClass === 'xs' ? 'mini' : 'standard';
    const width = screenClass === 'xs' ? 'standard' : 'full-width';

    return (
        <FlexBox
            gap="between-actions"
            justifyContent={screenClass === 'xs' ? 'flex-end' : 'flex-start'}
        >
            <DiscardButton revisionRequestId={revisionRequestId} width={width} />
            <SubmitButton
                isSubmitEnabled={isSubmitEnabled}
                revisionRequestId={revisionRequestId}
                spinnerSize={spinnerSize}
                width={width}
            />
        </FlexBox>
    );
};
