import React from 'react';
import styled from 'styled-components';
import { StepProvider, useStepContext } from '../context/StepContext';
import { TopBar, TopBarProps } from './TopBar';

const ShellOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    width: 100vw;
    overflow-x: hidden;
`;

const StepWrapper = ({ stepIndex, children }: { stepIndex: number; children: React.ReactNode }) => {
    const { currentStep } = useStepContext();
    return (
        <div style={{ display: currentStep === stepIndex ? 'flex' : 'none', width: '100%' }}>
            {children}
        </div>
    );
};

export const MultiStepLayout = ({
    children,
    title,
    exitUrl,
}: {
    children: React.ReactNode;
} & TopBarProps) => {
    return (
        <ShellOuter>
            <TopBar title={title} exitUrl={exitUrl} />
            {children}
        </ShellOuter>
    );
};

export type MultiStepProps = {
    steps: JSX.Element[];
    validate: (stepIndex: number) => Promise<boolean>;
    Layout?: React.ComponentType<{ children: React.ReactNode } & TopBarProps>;
    trackSteps?: boolean;
} & TopBarProps;

export const MultiStep = ({
    steps,
    validate,
    title,
    exitUrl,
    Layout = MultiStepLayout,
    trackSteps,
}: MultiStepProps) => {
    return (
        <StepProvider totalSteps={steps.length} validate={validate} trackSteps={trackSteps}>
            <Layout title={title} exitUrl={exitUrl}>
                {steps.map((s, i) => {
                    return (
                        <StepWrapper stepIndex={i} key={i}>
                            {s}
                        </StepWrapper>
                    );
                })}
            </Layout>
        </StepProvider>
    );
};
