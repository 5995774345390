/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type GetCollaborationQueryVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
}>;

export type GetCollaborationQuery = {
    __typename: 'Query';
    getCollaboration: {
        __typename: 'GetCollaborationResponse';
        state: Types.DeliveryState;
        mpvId: string;
        culture: string;
        briefId: string;
        viewerRole: Types.ViewerRole;
        hasDesignLiveDelivery: boolean;
        isTest: boolean;
        deliveryVersions: Array<{ __typename: 'DeliveryVersion'; versionId: number }>;
        permissions: { __typename: 'CollaborationPermissions'; canRequestRevision: boolean };
        printProduct: {
            __typename: 'CollaboratePrintProduct';
            mpvId: string;
            productOptionsJson: Types.Maybe<string>;
        };
        revisions: {
            __typename: 'Revisions';
            isUnlimited: boolean;
            maximum: number;
            remaining: number;
        };
    };
};

export const GetCollaborationDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCollaboration' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getCollaboration' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'collaborationPublicId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'collaborationPublicId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deliveryVersions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'versionId' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mpvId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'culture' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'permissions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'canRequestRevision' },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'briefId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'viewerRole' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasDesignLiveDelivery' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'isTest' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'printProduct' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mpvId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'productOptionsJson' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revisions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isUnlimited' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'maximum' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'remaining' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __useGetCollaborationQuery__
 *
 * To run a query within a React component, call `useGetCollaborationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationQuery({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *   },
 * });
 */
export function useGetCollaborationQuery(
    baseOptions: Apollo.QueryHookOptions<GetCollaborationQuery, GetCollaborationQueryVariables>
) {
    return Apollo.useQuery<GetCollaborationQuery, GetCollaborationQueryVariables>(
        GetCollaborationDocument,
        baseOptions
    );
}
export function useGetCollaborationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCollaborationQuery, GetCollaborationQueryVariables>
) {
    return Apollo.useLazyQuery<GetCollaborationQuery, GetCollaborationQueryVariables>(
        GetCollaborationDocument,
        baseOptions
    );
}
export type GetCollaborationQueryHookResult = ReturnType<typeof useGetCollaborationQuery>;
export type GetCollaborationLazyQueryHookResult = ReturnType<typeof useGetCollaborationLazyQuery>;
export type GetCollaborationQueryResult = Apollo.QueryResult<
    GetCollaborationQuery,
    GetCollaborationQueryVariables
>;
