import { ApolloError } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { AlertBox, BoundedContent, Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';

/**
 * Wrapper for the loading and error states of components that use Apollo client.
 * This function takes the result of a query and renders a function with a non nullable
 * version of data when the loading is done. If there are errors, it will render an error message.
 */
export function ErrorLoadingState<T>({
    loading,
    loadingAccessibleText = __('Loading'),
    errors,
    errorContext,
    data,
    children,
}: {
    loading: boolean;
    loadingAccessibleText?: string;
    // if an error occurs, this string will be passed to bugsnag as additional context
    errorContext?: string;
    errors: ApolloError[] | ApolloError | undefined;
    data: T | undefined;
    children: (data: T) => JSX.Element | undefined;
}) {
    if (loading)
        return (
            <BoundedContent padding={'5'}>
                <Spinner accessibleText={loadingAccessibleText} />
            </BoundedContent>
        );

    if (errors instanceof ApolloError) {
        Bugsnag.notify(errors, (event) => {
            event.context = errorContext;
        });

        return (
            <BoundedContent padding="5">
                <AlertBox toast skin="error">
                    {__('Sorry, there has been an error. Please refresh or try again later.')}
                </AlertBox>
            </BoundedContent>
        );
    }

    if (errors && errors.length) {
        errors.forEach((error) => {
            Bugsnag.notify(error, (event) => {
                event.context = errorContext;
            });
        });
        return (
            <BoundedContent padding="5">
                <AlertBox toast skin="error">
                    {__('Sorry, there has been an error. Please refresh or try again later.')}
                </AlertBox>
            </BoundedContent>
        );
    }

    return data ? <>{children(data)}</> : null;
}
