import React, { PropsWithChildren } from 'react';
import { Box, responsive } from '@vp/swan';

const ResponsiveBox = responsive(Box);

export default function StepDescriptionBox({
    children,
    isModal,
}: PropsWithChildren & { isModal: boolean }) {
    return (
        <ResponsiveBox
            textAlign="left"
            mt={2}
            style={{
                maxWidth: isModal ? undefined : '180px',
            }}
            sm={{
                textAlign: isModal ? 'left' : 'center',
            }}
            md={{
                textAlign: 'left',
            }}
        >
            {children}
        </ResponsiveBox>
    );
}
