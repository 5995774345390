import React from 'react';
import { Button, Icon, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { VersionDropdown } from '../VersionDropdown';
import { useScroll } from './useScroll';
import { useZoom } from './useZoom';

export type DesignPreviewCanvasProps = {
    images: string[][];
    version: number;
    setVersion: (version: number) => void;
};

export const NonScrollingWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${tokensRaw.SwanBaseColorGrey100};
    padding-bottom: 150px; // To make room for the thumbnails
`;

export const GridContainer = styled.div`
    overflow: scroll;
    height: 100%;
`;

export const ImageColumnsContainer = styled.div<{ zoom: number }>`
    display: flex;
    width: max-content;
    gap: ${tokensRaw.SwanSemSpace5};
    transform: scale(${(props) => props.zoom});
    transform-origin: top left;
`;

export const ImageColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${tokensRaw.SwanSemSpace5};
`;

export const StyledImage = styled.img`
    display: block;
    width: auto;
    max-height: 100%;
    object-fit: contain;
`;

export const ThumbnailImage = styled.img`
    display: block;
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid ${tokensRaw.SwanBaseColorGrey200};
`;

export const NavigationButton = styled.div`
    position: absolute;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);
`;

export const NavigationButtonLeft = styled(NavigationButton)`
    left: ${tokensRaw.SwanSemSpace5};
`;

export const NavigationButtonRight = styled(NavigationButton)`
    right: ${tokensRaw.SwanSemSpace5};
`;

export const Header = styled.div`
    position: sticky;
    width: 100%;
    padding: ${tokensRaw.SwanSemSpace5} ${tokensRaw.SwanSemSpace7} ${tokensRaw.SwanSemSpace4};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${tokensRaw.SwanSemSpace3};
    flex: none;
`;

export const ZoomControls = styled.div`
    display: inline-flex;
    flex-direction: row-reverse;
    gap: ${tokensRaw.SwanSemSpace3};
    align-items: center;
`;

export const Thumbnails = styled.div`
    position: absolute;
    bottom: 0;
    background-color: rgba(248, 248, 248, 0.8);
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: ${tokensRaw.SwanSemSpace3};
    z-index: 3;
    padding: ${tokensRaw.SwanSemSpace3};
    border-radius: ${tokensRaw.SwanSemSpace5};
`;

export const DesignPreviewCanvas: React.FC<DesignPreviewCanvasProps> = ({
    images,
    version,
    setVersion,
}) => {
    const { zoom, handleZoomIn, handleZoomOut, handleResetZoom } = useZoom();
    const {
        currentColumn,
        navigateToColumn,
        debouncedHandleScroll,
        imageColumnsContainerRef,
        gridRef,
    } = useScroll(images);

    return (
        <NonScrollingWrapper>
            <Header>
                <VersionDropdown
                    version={version}
                    revisionsRemaining={0}
                    setVersion={setVersion}
                    revisionsTotal={3}
                    hasUnlimitedRevisions={false}
                />
                <ZoomControls>
                    <Button onClick={handleZoomIn} buttonShape="round">
                        <Icon alt="Zoom In" iconType="zoomIn" />
                    </Button>
                    <Button onClick={handleZoomOut} buttonShape="round">
                        <Icon alt="Zoom Out" iconType="zoomOut" />
                    </Button>
                    <Button onClick={handleResetZoom} buttonShape="round">
                        <Icon alt="Reset Zoom" iconType="reset" />
                    </Button>
                </ZoomControls>
            </Header>

            {currentColumn > 0 && (
                <NavigationButtonLeft onClick={() => navigateToColumn(currentColumn - 1)}>
                    <Button buttonShape="round" onClick={() => navigateToColumn(currentColumn - 1)}>
                        <Icon iconType="arrowLeft" />
                    </Button>
                </NavigationButtonLeft>
            )}
            {currentColumn < images.length - 1 && (
                <NavigationButtonRight>
                    <Button buttonShape="round" onClick={() => navigateToColumn(currentColumn + 1)}>
                        <Icon iconType="arrowRight" />
                    </Button>
                </NavigationButtonRight>
            )}

            <GridContainer ref={gridRef} onScroll={debouncedHandleScroll}>
                <ImageColumnsContainer zoom={zoom} ref={imageColumnsContainerRef}>
                    {images.map((column, columnIndex) => (
                        <ImageColumn key={columnIndex}>
                            {column.map((image, imageIndex) => (
                                <StyledImage
                                    key={imageIndex}
                                    src={image}
                                    alt={`Image ${columnIndex}-${imageIndex}`}
                                />
                            ))}
                        </ImageColumn>
                    ))}
                </ImageColumnsContainer>
            </GridContainer>

            <Thumbnails>
                {images.map((column, columnIndex) => (
                    <ThumbnailImage
                        style={{
                            opacity: currentColumn === columnIndex ? 1 : 0.5,
                        }}
                        onClick={() => navigateToColumn(columnIndex)}
                        key={columnIndex}
                        src={column[0]}
                        alt={`Image ${columnIndex}`}
                    />
                ))}
            </Thumbnails>
        </NonScrollingWrapper>
    );
};
