import { createContext, useContext } from 'react';

export interface ViewContextProps {
    readonly isReviewMode: boolean;
    readonly isMultiStep: boolean;
}

export const ViewContext = createContext<Partial<ViewContextProps>>({
    isReviewMode: false,
    isMultiStep: false,
});

export const ViewContextProvider = ViewContext.Provider;

export const useViewContext = () => {
    return useContext(ViewContext);
};

export const useIsReviewMode = () => {
    return useViewContext().isReviewMode;
};

export const useIsMultiStep = () => {
    return useViewContext().isMultiStep;
};
