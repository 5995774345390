import { Link } from 'react-router-dom';
import { Button, Divider, Typography, tokens } from '@vp/swan';
import { FormattedReactMessage } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { COLLABORATE_BASE_PATH } from '../constants';
import { CollaborationEvent } from './CollaborationEvent';

export interface RevisionRequestEventProps {
    message?: string;
    deliveryVersionId: number;
    maxRevisionRequests: number;
    collaborationPublicId: string;
    hasUnlimitedRevisions: boolean;
}

export const RevisionRequestEvent: React.FC<RevisionRequestEventProps> = ({
    message,
    deliveryVersionId,
    maxRevisionRequests,
    collaborationPublicId,
    hasUnlimitedRevisions,
}) => {
    return (
        <CollaborationEvent alignmentDirection="right" color={tokens.SwanSemColorBgAccent}>
            {message && (
                <>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                        <i>{message}</i>
                    </Typography>
                    <Divider my={'5'} />
                </>
            )}
            <Typography marginTop={'3'}>
                <FormattedReactMessage
                    message={
                        hasUnlimitedRevisions
                            ? __(
                                  'Revision request successfully submitted for <Link>Version {deliveryVersionId}</Link>. The updated design will be ready within 4 hours.'
                              )
                            : __(
                                  'Revision request successfully submitted for <Link>Version {deliveryVersionId}</Link>. You have {remainingRevisions} remaining. The updated design will be ready within 24 hours.'
                              )
                    }
                    replacements={{
                        Link: (
                            <Link
                                to={`${COLLABORATE_BASE_PATH}/${collaborationPublicId}/delivery/${deliveryVersionId}?activePanel=designs`}
                            />
                        ),
                    }}
                    values={{
                        deliveryVersionId: deliveryVersionId,
                        remainingRevisions: maxRevisionRequests - deliveryVersionId,
                    }}
                />
            </Typography>
            <Button
                marginY={'3'}
                fontSkin={'body-standard-bold'}
                width="full-width"
                render={(props) => (
                    <Link
                        to={`${COLLABORATE_BASE_PATH}/${collaborationPublicId}/delivery/${deliveryVersionId}/revision`}
                        className={props.className}
                    >
                        {props.children}
                    </Link>
                )}
            >
                {__('View feedback')}
            </Button>
        </CollaborationEvent>
    );
};
