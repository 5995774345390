import React, { useRef } from 'react';
import { Box, Button, FlexBox, Icon, PipeSeparator, Typography } from '@vp/swan';
import { StatusCircle } from '@99designs/design-services-layouts';
import { Drawer, DrawerContent, useDefaultDrawerProps } from '@99designs/design-services-layouts';
import { DeliveryState } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { getCollaborationStatus } from '../../../util/getCollaborationStatus';
import { ImageRef } from '../DesignPreviewCanvas/ImageRef';
import { ThumbnailBarCompact } from '../DesignPreviewCanvas/ThumbnailBar';
import { useDeliveryQueryParams } from '../DesignPreviewCanvas/useDeliveryQueryParams';
import { PinComment } from '../PinComment/PinComment';
import { VersionDrawer } from '../VersionDrawer';
import { DrawerActionButtons } from './DrawerActionButtons';

export interface DrawerHomeProps {
    deliveryHasRevisionRequest?: boolean;
    images: { [key: number]: ImageRef[] };
    interactable?: boolean;
    pins: PinComment[];
    revisionsRemaining: number;
    revisionsTotal: number;
    hasUnlimitedRevisions: boolean;
    setVersion: (version: number) => void;
    state: DeliveryState;
    version: number;
}

export const DrawerHome: React.FC<DrawerHomeProps> = ({
    images,
    interactable = false,
    pins,
    revisionsRemaining,
    revisionsTotal,
    hasUnlimitedRevisions,
    setVersion,
    state,
    version,
    deliveryHasRevisionRequest,
}) => {
    const status = getCollaborationStatus(state);
    const { deliveryVariation, setDeliveryVariationQueryParams } = useDeliveryQueryParams(images);
    const hasMultipleVariations = Object.keys(images).length > 1;

    const drawerRef = useRef<HTMLDivElement>(null);
    const drawer = useDefaultDrawerProps(drawerRef, {
        initialOpenState: true,
        collapsedStateOffset: 145, // always display 145px of the drawer when it's collapsed
    });
    const versionDrawer = useDefaultDrawerProps(drawerRef, undefined);
    const allowChangingVersions = !interactable; // interactable value refers to the canvas interactivity - i.e. does it allow pinned comments? However, we do not allow changing version when requesting a revision as revision requests only happen on the latest version

    return (
        <>
            <Drawer {...drawer} ref={drawerRef} isDraggable>
                <DrawerContent
                    aria-label="Canvas drawer"
                    paddingTop="2"
                    paddingBottom="12" // To account for BottomBar
                >
                    <FlexBox justifyContent="center" marginBottom="2">
                        {allowChangingVersions ? (
                            <Button
                                skin="tertiary"
                                iconPosition="right"
                                onClick={() => versionDrawer.handleOpen?.()}
                                data-testid="version-drawer-trigger"
                            >
                                {__('Version {{version}}', { version })}
                                <Icon iconType="caretDown" />
                            </Button>
                        ) : (
                            <Typography
                                textAlign="center"
                                fontSkin="body-small-bold"
                                marginBottom="3"
                            >
                                {__('Version {{version}}', { version })}
                            </Typography>
                        )}
                    </FlexBox>

                    <FlexBox
                        justifyContent="center"
                        alignItems="center"
                        gap="5"
                        marginBottom="to-actions"
                    >
                        <FlexBox gap="4" alignItems="center">
                            <StatusCircle color={status.color} />
                            <Typography fontSkin="body-small">{status.label}</Typography>
                        </FlexBox>

                        {hasUnlimitedRevisions && (
                            <>
                                <PipeSeparator />

                                <Typography fontSkin="body-small" textColor="subtle">
                                    {revisionsRemaining === 1
                                        ? __('1 revision left')
                                        : __('{{x}} revisions left', { x: revisionsRemaining })}
                                </Typography>
                            </>
                        )}
                    </FlexBox>

                    {hasMultipleVariations && (
                        <Box marginBottom="to-actions">
                            <ThumbnailBarCompact
                                images={images}
                                pins={pins}
                                deliveryVariation={deliveryVariation}
                                setDeliveryVariationQueryParams={setDeliveryVariationQueryParams}
                            />
                        </Box>
                    )}

                    <Box style={{ maxWidth: '100%' }}>
                        <DrawerActionButtons
                            deliveryHasRevisionRequest={deliveryHasRevisionRequest}
                        />
                    </Box>
                </DrawerContent>
            </Drawer>

            {/* Since this is another drawer, it cannot be nested within another drawer as it needs its' own motion container */}
            <VersionDrawer
                {...versionDrawer}
                revisionsTotal={revisionsTotal}
                setVersion={setVersion}
                version={version}
            />
        </>
    );
};
