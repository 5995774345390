import { Card, Column, FlexBox, GridContainer, Row, Spinner, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useIsMultiStep } from '../../ViewContext';
import { useWorkEntityFieldQuery } from './WorkEntityField.generated';
import WorkEntityViews from './WorkEntityViews';

interface WorkEntityFieldProps {
    workEntityValue: string;
    title?: string;
    description?: string | null;
}

export default function WorkEntityField(props: WorkEntityFieldProps) {
    const isMultiStep = useIsMultiStep();
    const { data, loading, error } = useWorkEntityFieldQuery({
        variables: {
            workId: props.workEntityValue,
        },
    });

    if (error) {
        throw error;
    }

    if (loading || !data) {
        return <Spinner accessibleText={__('Loading…')} />;
    }

    return isMultiStep ? (
        <Card
            mt={5}
            p={4}
            swanStyle={{
                backgroundColor: 'info',
            }}
        >
            <GridContainer>
                <Row>
                    <Column span={8}>
                        <Typography fontSkin="title-item">{props.title}</Typography>
                        <Typography fontSkin="body-small">{props.description}</Typography>
                    </Column>
                    <Column span={4}>
                        <FlexBox>
                            <WorkEntityViews views={data.workEntityViews.views} />
                        </FlexBox>
                    </Column>
                </Row>
            </GridContainer>
        </Card>
    ) : (
        <WorkEntityViews views={data.workEntityViews.views} />
    );
}
