import { PropsWithChildren } from 'react';
import { FlexBox, responsive } from '@vp/swan';

const ResponsiveFlexBox = responsive(FlexBox);

export default function StepTitleContainer({
    children,
    isModal,
}: PropsWithChildren & { isModal: boolean }) {
    return (
        <ResponsiveFlexBox
            style={{
                alignSelf: 'flex-start',
            }}
            sm={{
                style: {
                    alignSelf: isModal ? 'stretch' : 'center',
                },
            }}
        >
            {children}
        </ResponsiveFlexBox>
    );
}
